<div class="container-fluid" *ngIf="schedulingData">
  <div class="row">
    <div class="col-6 ps-0">
      <div class="meetings-list">
        <h3 class="text-primary">Reuniões agendadas</h3>
        <p>Todas as reuniões que foram marcadas pelo Calendly para este contato. Reuniões marcadas diretamente na agenda não serão listadas.</p>

        <div class="meetings-list-items pe-3" *ngIf="schedulingData.meetings.length">
          <div class="meeting-container border rounded mb-3 d-flex" [class.scheduled]="!schedule.isPast" *ngFor="let schedule of schedulingData.meetings | orderby: 'start_time': 'desc'">
            <div class="meeting-status border-end bg-opacity-10 p-4">
              <i class="fa-duotone fa-solid fa-calendar-check fs-3"></i>
            </div>
            <div class="meeting-content flex-fill p-3">
              <p class="lead mb-1">Reunião com o vendedor <strong>{{schedule.owner.name}}</strong> no dia <strong>{{schedule.start_time | date:"dd MMM yyyy 'às' HH:mm"}}</strong></p>
              <p class="mb-0">Marcado por {{schedule.sdr.email}} e acaba às {{schedule.end_time | date:"HH:mm"}}</p>
              <div class="text-end mt-3" *ngIf="!schedule.isPast">
                <a href="https://www.nibo.com.br/agendamento-de-reuniao?email={{schedulingData.contact.email}}" class="btn btn-sm text-primary"><i class="fa-duotone fa-solid fa-video me-1"></i> Abrir link da reunião</a>
                <a [href]="schedule.reschedule_url" class="btn btn-sm text-primary"><i class="fa-duotone fa-solid fa-calendar-pen me-1"></i> Reagendar</a>
                <a [href]="schedule.cancel_url" class="btn btn-sm text-danger pe-0"><i class="fa-solid fa-xmark me-1"></i> Cancelar</a>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!schedulingData.meetings.length">
          <p class="lead text-center my-5">Nenhuma reunião via Calendly encontrada</p>
        </div>

        <div class="text-end mt-3 pe-3">
          <a class="btn btn-secondary" routerLink="/reunioes/nova/" [queryParams]="{contato: schedulingData.contact.email}"><i class="fa-duotone fa-solid fa-calendar-circle-plus me-2"></i> Nova reunião</a>
        </div>
      </div>      
    </div>
  </div>
</div>
